/**
 *  Helper methods for processing amounts.
 * TODO update this with a plugin that takes into consideration locale and currency when formatting the amount value.
 */

/**
 * Regex that allows the user to input a positive amount with comma or space thousands separator, full stop decimal
 * place separator and one or two decimal places.
 *
 * ^: Start of the line.
 * (?: ... ): A non-capturing group to group different alternatives.
 * (?!0\d): A negative lookahead to ensure that the number doesn't start with a zero followed by any digit. This prevents leading zeros like "0123".
 * \d{1,3}: Matches 1 to 3 digits (for the part before the thousands separator).
 * (?:[,\s]?\d{3})*: This part allows for thousands separators. It matches an optional comma or space followed by exactly three digits, and this group can be repeated zero or more times.
 * |: OR operator, allowing for either a number with thousands separators or without them.
 * \d+: Matches one or more digits (for numbers without thousands separators).
 * (?:\.\d{1,2})?: This part allows for the decimal portion. It matches a dot followed by one or two digits, and this group is optional.
 * $: End of the line.
 */
import { usePiniaStore } from "../plugins/store";
export const amountRegex =
    "^(?:(?!0\\d)(?:\\d{1,3}(?:[,\\s]?\\d{3})*|\\d+))(?:\\.\\d{1,2})?$";

export const removeCommasAndSpaces = (inputString: string) => {
    /**
     * Removes commas and spaces from an empty string. Used to format the amount
     * value that will be sent in requests to the API
     * @param {[string]} inputString
     * @return {[string]}
     */
    const regex = /[, ]/g; // Regular expression to match commas and spaces globally
    return inputString.replace(regex, "");
};

export const formatAmountBasedOnLocaleAndCurrency = (
    locale: string,
    currencyCode: string,
    amount: string,
): string => {
    /**
     * Uses the Intl.NumberFormat object enables language-sensitive number formatting
     * to format the amount based on the user's locale and the amount's currency
     * @param {[string]} locale
     * @param {[string]} currencyCode
     * @param {[string]} amount
     * @return {[string]}
     */
    const formattedAmount = new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currencyCode,
    }).format(Number(amount));

    return formattedAmount;
};

export const formatProviderAmountBasedOnLocaleAndCurrency = (
    amount: string,
) => {
    /**
     * Uses the Intl.NumberFormat object enables language-sensitive number formatting
     * to format the amount based on the the user's locale and the selected payment method
     * @param {[string]} amount
     * @return {[string]}
     */
    const pStore = usePiniaStore();

    const provider = pStore.getSelectedProvider;

    const locale = pStore.getLanguage !== null ? pStore.getLanguage : "en-GB";

    const paymentMethods = pStore.getPaymentMethods;

    if (paymentMethods !== null) {
        const paymentMethodUsed = paymentMethods.find(
            (obj) => obj.paymentMethod === provider,
        );

        if (paymentMethodUsed !== undefined) {
            const formattedAmount = new Intl.NumberFormat(locale, {
                style: "currency",
                currency: paymentMethodUsed.currencyCode,
            }).format(Number(amount));

            return formattedAmount;
        } else {
            return "";
        }
    }
};
