/**
 * Sentry error helpers. Can be imported in any component.
 */
import * as Sentry from "@sentry/vue";

export const configSentryError = (
    componentName: string,
    method: string,
    errorMessage: string,
) => {
    /**
     * Configures the Sentry error structure
     * @param  {[string]} componentName - component where the error is triggered from
     * @param  {[string]} method - method/function where the error is triggered from
     * @param  {[string]} errorMessage - the triggered error
     * @return {[void]} void
     */
    const transactionName = componentName + " | " + method;
    Sentry.withScope(function (scope) {
        scope.setTransactionName(transactionName);
        Sentry.captureException(new Error(errorMessage));
    });
};

export const setSentryUser = (emailAddress: string, sessionID: string) => {
    /**
     * Sets the user's identity
     * @param  {[string]} emailAddress
     * @param  {[string]} sessionID - ties the user to a session
     * @return {[void]} void
     */
    Sentry.setUser({
        id: sessionID,
        email: emailAddress,
    });
};

export const configSentryApiError = (
    transactionName: string,
    errorMessage: string,
) => {
    /**
     * Configures the Sentry error structure
     * @param  {[string]} transactionName - component/method where the error is triggered from
     * @param  {[string]} errorMessage - the triggered error
     * @return {[void]} void
     */
    Sentry.withScope(function (scope) {
        scope.setTransactionName(transactionName);
        Sentry.captureException(new Error(errorMessage));
    });
};

export const createSentryBreadcrumb = (
    category: string,
    message: string,
    level: Sentry.SeverityLevel,
) => {
    /**
     * Manually adds a breadcrumb in the Sentry event.
     * Breadcrumb config - https://develop.sentry.dev/sdk/event-payloads/breadcrumbs/
     * @param  {[string]} category - "started", "sentry.transaction", "ui.click", "click", "navigation", "error", "xhr"
     * @param  {[string]} message - the breadcrumb message
     * @param  {[Sentry.SeverityLevel]} level - "fatal", "critical", "error", "warning", "log", "info", and "debug".
     * @return {[void]} void
     */
    Sentry.addBreadcrumb({
        category: category,
        message: message,
        level: level,
    });
};

export const captureSentryMessage = (message: string) => {
    Sentry.withScope(function (scope) {
        scope.setLevel("info");
        // The exception has the event level set by the scope (info).
        Sentry.captureMessage(message, "info");
    });
};
