import { defineStore } from "pinia";
import { v4 as uuidv4 } from "uuid";
import {
    getCountryCodeFromTimezone,
    getCountryCodeFromIPAddress,
} from "./../../helpers/timezone";
import { User } from "../../helpers/interfaces/auth";
import {
    PayerInformation,
    PartnerLinkInformation,
} from "../../helpers/interfaces/shared";
import {
    Opayo3DSAuth,
    CurrencycloudCreatePaymentResponse,
    YapilyCreatePaymentAuthorisationResponse,
    TruelayerCreatePaymentResponse,
} from "../../helpers/interfaces/providers";
import {
    Organisation,
    Form,
    PaymentMethod,
} from "../../helpers/interfaces/organisations";

export const usePiniaStore = defineStore("store", {
    state: () => ({
        countryCode: null as string | null,
        language: null as string | null,
        shortcode: null as string | null,
        formName: null as string | null,
        sessionId: null as string | null,
        emailAddress: null as string | null,
        user: null as User | null,
        organisation: null as Organisation | null,
        formStructure: null as Form | null,
        supplementaryData: null as any,
        payerInformation: null as PayerInformation | null,
        triggerPayerInformationValidation: false as boolean,
        opayoAuthInformation: null as Opayo3DSAuth | null,
        paymentId: null as string | null,
        paymentMethods: null as PaymentMethod[] | null,
        selectedProvider: null as string | null,
        triggerCardInformationValidation: false as boolean,
        currencycloudPaymentInformation:
            null as CurrencycloudCreatePaymentResponse | null,
        yapilyPaymentInformation:
            null as YapilyCreatePaymentAuthorisationResponse | null,
        truelayerPaymentInformation:
            null as TruelayerCreatePaymentResponse | null,
        partnerLinkInformation: null as PartnerLinkInformation | null,
        loadNuveiScript: false as boolean,
        loadOpayoScript: false as boolean,
    }),
    getters: {
        getCountryCode: (state) => state.countryCode,
        getLanguage: (state) => state.language,
        getShortcode: (state) => state.shortcode,
        getFormName: (state) => state.formName,
        getSessionId: (state) => state.sessionId,
        getUser: (state) => state.user,
        getEmailAddress: (state) => state.emailAddress,
        getOrganisation: (state) => state.organisation,
        getFormStructure: (state) => state.formStructure,
        getSupplementaryData: (state) => state.supplementaryData,
        getPayerDetails: (state) => state.payerInformation,
        getTriggerPayerInformationValidation: (state) =>
            state.triggerPayerInformationValidation,
        getOpayoAuthInformation: (state) => state.opayoAuthInformation,
        getPaymentId: (state) => state.paymentId,
        getPaymentMethods: (state) => state.paymentMethods,
        getSelectedProvider: (state) => state.selectedProvider,
        getTriggerCardInformationValidation: (state) =>
            state.triggerCardInformationValidation,
        getCurrencycloudPaymentInformation: (state) =>
            state.currencycloudPaymentInformation,
        getYapilyPaymentInformation: (state) => state.yapilyPaymentInformation,
        getPartnerLinkInformation: (state) => state.partnerLinkInformation,
        getTruelayerPaymentInformation: (state) =>
            state.truelayerPaymentInformation,
        getLoadOpayoScript: (state) => state.loadOpayoScript,
        getLoadNuveiScript: (state) => state.loadNuveiScript,
    },
    actions: {
        async setDefaultCountryCode() {
            /**
             * Sets the default country code based on the user's timezone
             * If no timezone is found, it falls back on IP address lookup.
             */
            if (this.countryCode === null || this.countryCode.length === 0) {
                this.countryCode = getCountryCodeFromTimezone();
            }

            if (this.countryCode === undefined) {
                this.countryCode = await getCountryCodeFromIPAddress();
            }

            if (this.countryCode !== undefined) {
                if (
                    this.countryCode === null ||
                    this.countryCode.length !== 2
                ) {
                    this.countryCode = await getCountryCodeFromIPAddress();
                }
            }
        },
        updateCountryCode(manuallySelectedCountryCode: string) {
            /**
             * Updates the country code based on a user selected value
             */
            this.countryCode = manuallySelectedCountryCode;
        },
        setDefaultLanguage() {
            /**
             * Sets the default language based on a the language configured in the browser
             * It gets the first value from the navigator.languages list if this list is not
             * undefined. Otherwise, it gets the value from navigator.language.
             * If both navigator.languages and navigator.language are undefined, it defaults
             * to en-GB
             */
            this.language =
                navigator.languages && navigator.languages.length
                    ? navigator.languages[0]
                    : navigator.language || "en-GB";
        },
        updateLanguage(manuallySelectedLanguage: string) {
            /**
             * Updates the language code based on a user selected value
             */
            this.language = manuallySelectedLanguage;
        },
        setFormName(type: string | null) {
            /**
             * Sets the payment type/init.
             * @param  {[string]} type - taken from the url parameter
             * @return {[void]} void
             */

            /**
             * TODO update the stored value based on the value returned from the BE
             * */

            if (type === "undefined") {
                this.formName = "invoice";
            }

            if (type !== "undefined") {
                if (type !== "") this.formName = type;
                else this.formName = "invoice";
            }
        },
        setSessionId(sessionId = uuidv4()) {
            /**
             * Sets a user session id to an UUIDv4 value
             */
            this.sessionId = sessionId;
        },
        setShortcode(orgShortcode: string | null) {
            /**
             * Sets the organisation shortcode to a given value
             */
            this.shortcode = orgShortcode;
        },
        setEmailAddress(email: string | null) {
            /**
             * Sets email address
             */
            this.emailAddress = email;
        },
        setUser(user: User | null) {
            /**
             * Sets Auth0 user
             */
            this.user = user;
        },
        setOrganisation(org: Organisation | null) {
            /**
             * Sets the organisation object to a given value
             */
            this.organisation = org;
        },
        setFormStructure(form: Form | null) {
            /**
             * Sets the supplementary form structure
             */
            this.formStructure = form;
        },
        setSupplementaryData(data: any) {
            /**
             * Sets the supplementary object collected from the user
             */
            this.supplementaryData = data;
        },
        updateSupplementaryData(updatedData: any) {
            /**
             * Updates the supplementary data object with the values received.
             * If the supplementary data is initially null it sets it to an empty
             * object to avoid type errors. it loops each key in the new object and
             * checks if the key exist in the original one. if it does it then updates
             * the key's value with the one from the passed object.
             */
            if (this.supplementaryData === null) {
                this.supplementaryData = {};
            }

            const updatedObject = { ...this.supplementaryData };

            for (const key in updatedData) {
                if (Object.prototype.hasOwnProperty.call(updatedData, key)) {
                    /** Type assertion here since we know 'key' exists in 'updatedData' */
                    const updatedValue = (updatedData as any)[key];
                    (updatedObject as any)[key] = updatedValue;
                }
            }

            this.supplementaryData = updatedObject;
        },
        setPayerDetails(information: PayerInformation | null) {
            /**
             * Sets the address object to a given value
             */
            this.payerInformation = information;
        },
        setTriggerPayerInformationValidation(value: boolean) {
            /**
             * Sets the boolean value for the payer information validation
             */
            this.triggerPayerInformationValidation = value;
        },
        setPaymentMethods(methods: PaymentMethod[] | null) {
            /**
             * Sets the list of allowed payment methods in the organisation's base currency
             */
            this.paymentMethods = methods;
        },
        setSelectedProvider(provider: string | null) {
            /**
             * Sets the provider to a given value to ensure that session reload
             * takes into account the user's selected payment method
             */
            this.selectedProvider = provider;
        },
        setOpayoAuthInformation(authInformation: Opayo3DSAuth | null) {
            /**
             * Sets the Opayo authentication object
             */
            this.opayoAuthInformation = authInformation;
        },
        setPaymentId(id: string | null) {
            /**
             * Sets the transaction id
             */
            this.paymentId = id;
        },
        setTriggerCardInformationValidation(value: boolean) {
            /**
             * Sets the boolean value for the card information trigger
             */
            this.triggerCardInformationValidation = value;
        },
        setCurrencycloudPaymentInformation(
            information: CurrencycloudCreatePaymentResponse | null,
        ) {
            /**
             * Sets the Currencycloud information object
             */
            this.currencycloudPaymentInformation = information;
        },
        setYapilyPaymentInformation(
            information: YapilyCreatePaymentAuthorisationResponse | null,
        ) {
            /**
             * Sets the Yapily information object
             */
            this.yapilyPaymentInformation = information;
        },
        setTruelayerPaymentInformation(
            information: TruelayerCreatePaymentResponse | null,
        ) {
            this.truelayerPaymentInformation = information;
        },
        setPartnerLinkInformation(information: PartnerLinkInformation | null) {
            this.partnerLinkInformation = information;
        },
        updatePartnerLinkInformation(updatedData: any) {
            /**
             * Updates the partner link data object with the values received.
             * If the partner link data is initially null it sets it to an empty
             * object to avoid type errors. it loops each key in the new object and
             * checks if the key exist in the original one. if it does it then updates
             * the key's value with the one from the passed object.
             */
            if (this.partnerLinkInformation === null) {
                this.partnerLinkInformation = {};
            }

            const updatedObject = { ...this.partnerLinkInformation };

            for (const key in updatedData) {
                if (Object.prototype.hasOwnProperty.call(updatedData, key)) {
                    /** Type assertion here since we know 'key' exists in 'updatedData' */
                    const updatedValue = (updatedData as any)[key];
                    (updatedObject as any)[key] = updatedValue;
                }
            }

            this.partnerLinkInformation = updatedObject;
        },
        setLoadOpayoScript(load: boolean) {
            this.loadOpayoScript = load;
        },
        setLoadNuveiScript(load: boolean) {
            this.loadNuveiScript = load;
        },
        clearSessionData() {
            /**
             * Resets data to default values on session end
             */
            this.setSessionId(null);
            this.setUser(null);
            this.setEmailAddress(null);
            this.setSupplementaryData(null);
            this.setPayerDetails(null);
            this.setOpayoAuthInformation(null);
            this.setPaymentId(null);
            this.setPaymentMethods(null);
            this.setSelectedProvider(null);
            this.setTriggerPayerInformationValidation(false);
            this.setTriggerCardInformationValidation(false);
            this.setCurrencycloudPaymentInformation(null);
            this.setYapilyPaymentInformation(null);
            this.setPartnerLinkInformation(null);
            this.setTruelayerPaymentInformation(null);
        },
        clearSessionDataForAnotherPayment() {
            /** Resets certain values when the user starts a second payment */
            this.setSupplementaryData(null);
            this.setOpayoAuthInformation(null);
            this.setPaymentId(null);
            this.setPaymentMethods(null);
            this.setTriggerPayerInformationValidation(false);
            this.setTriggerCardInformationValidation(false);
            this.setCurrencycloudPaymentInformation(null);
            this.setYapilyPaymentInformation(null);
            this.setTruelayerPaymentInformation(null);
        },
    },
    persist: [
        {
            paths: [
                "countryCode",
                "language",
                "shortcode",
                "formName",
                "emailAddress",
                "sessionId",
                "user",
                "organisation",
                "formStructure",
                "supplementaryData",
                "payerInformation",
                "triggerPayerInformationValidation",
                "opayoAuthInformation",
                "paymentId",
                "paymentMethods",
                "selectedProvider",
                "triggerCardInformationValidation",
                "currencycloudPaymentInformation",
                "yapilyPaymentInformation",
                "partnerLinkInformation",
                "truelayerPaymentInformation",
                "loadOpayoScript",
                "loadNuveiScript",
            ],
            storage: sessionStorage,
        },
    ],
});
