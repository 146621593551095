import { apiServerUrl, ExternalApi } from "../apiService";
import { usePiniaStore } from "../../plugins/store";
import { keysToCamelCase } from "../../helpers/stringProcessors";
import {
    Organisation,
    Form,
    PaymentMethod,
} from "../../helpers/interfaces/organisations";
import { redirectToPaymentMethod } from "../../helpers/paymentMethodHelpers";

export const getOrganisationData = async (
    token: string | null,
    shortcode: string,
    formName: string,
) => {
    /**
     * Internal API call to retrieve the organisation data. If successful,
     * it will save the data to the session storage, otherwise it will redirect
     * to 404 or display an error.
     * @param  {[string | null]} token - auth0 token
     * @param  {[string]} shortcode - organisation shortcode
     * @param  {[string]} formName - invoice/others
     * @return {[{void}]}
     */
    const pStore = usePiniaStore();

    const config = {
        url: `${apiServerUrl}/organisations/${shortcode}?form-name=${formName}`,
        method: "GET",
        headers: {
            "user-session-id": pStore.getSessionId,
            "Accept-Language": pStore.getLanguage,
            "Access-Control-Allow-Origin": apiServerUrl,
        },
    };

    /** Attach the token to the authorisation headers if not null */
    if (token !== null) {
        config.headers["Authorization"] = `Bearer ${token}`;
    }

    /** Define the behaviour if an error occurs during the API call */
    const errorHandlerConfig = {
        userAction: "refresh", // user needs to refresh the page
        sentryTransactionName: "getOrganisationDataAPI",
        redirectTo: "404", // redirect to the 404 page
    };

    const externalApi = new ExternalApi();
    const { data } = await externalApi.callExternalApi(
        { config },
        errorHandlerConfig,
    );

    if (data !== null) {
        const organisation: Organisation = keysToCamelCase(data);
        pStore.setShortcode(shortcode);
        pStore.setOrganisation(organisation);
    }
};

export const getFormData = async (
    token: string | null,
    shortcode: string,
    formName: string,
) => {
    /**
     * Internal API call to retrieve the form structure. If successful,
     * it will save the data to the session storage, otherwise it will redirect
     * to 404 or display an error.
     * @param  {[string | null]} token - auth0 token
     * @param  {[string]} shortcode - organisation shortcode
     * @param  {[string]} formName - invoice/others
     * @return {[{void}]}
     */
    const pStore = usePiniaStore();

    const config = {
        url: `${apiServerUrl}/organisations/${shortcode}/forms?form-name=${formName}`,
        method: "GET",
        headers: {
            "user-session-id": pStore.getSessionId,
            "Accept-Language": pStore.getLanguage,
            "Access-Control-Allow-Origin": apiServerUrl,
        },
    };

    /** Attach the token to the authorisation headers if not null */
    if (token !== null) {
        config.headers["Authorization"] = `Bearer ${token}`;
    }

    /** Define the behaviour if an error occurs during the API call */
    const errorHandlerConfig = {
        userAction: "refresh", // user needs to refresh the page
        sentryTransactionName: "getFormDataAPI",
        redirectTo: "404", // redirect to the 404 page
    };

    const externalApi = new ExternalApi();
    const { data } = await externalApi.callExternalApi(
        { config },
        errorHandlerConfig,
    );

    if (data !== null) {
        const formStructure: Form = keysToCamelCase(data);
        pStore.setFormStructure(formStructure);
    }
};

export const getPaymentMethodsList = async (
    token: string | null,
    countryCode: string,
    amount: number,
) => {
    /**
     * Internal API call to retrieve the the payment methods list. If successful,
     * it will save the data to the session storage and redirect the user to the payment
     * page, otherwise it will display an error.
     * @param  {[string | null]} token - auth0 token
     * @param  {[string]} countryCode - two letter country code
     * @param  {[Number]} amount - payment amount
     * @return {[{void}]}
     */
    const pStore = usePiniaStore();

    const config = {
        url: `${apiServerUrl}/organisations/${pStore.getShortcode}/payment-methods?country=${countryCode}&amount=${amount}`,
        method: "GET",
        headers: {
            "user-session-id": pStore.getSessionId,
            "Accept-Language": pStore.getLanguage,
            "Access-Control-Allow-Origin": apiServerUrl,
        },
    };

    /** Attach the token to the authorisation headers if not null */
    if (token !== null) {
        config.headers["Authorization"] = `Bearer ${token}`;
    }

    /** Define the behaviour if an error occurs during the API call */
    const errorHandlerConfig = {
        userAction: "retry", // user needs to retry the action
        sentryTransactionName: "getPaymentMethodsListAPI",
    };

    const externalApi = new ExternalApi();
    const { data } = await externalApi.callExternalApi(
        { config },
        errorHandlerConfig,
    );

    if (data !== null) {
        const paymentMethods: PaymentMethod[] = keysToCamelCase(data);
        pStore.setPaymentMethods(paymentMethods);
        redirectToPaymentMethod(paymentMethods);
    }
};
