/**
 * Error handling methods
 */
import { ref, Ref } from "vue";
import { configSentryError } from "./sentry";

/**
 * Alert configuration object
 *  */
export const alertConfiguration: Ref<{
    color: string;
    message: string;
    visible: boolean;
    internationalisedMessage: boolean;
}> = ref({
    color: "",
    message: "",
    visible: false,
    internationalisedMessage: false,
});

export const showAlert = (
    message: string,
    internationalisedMessage = false,
    type = "error",
) => {
    /**
     * Sets the error alert configuration
     * @param  {[string]} message
     * @param  {[boolean]} internationalisedMessage - displays internationalisation option, defaults to false
     * @param {[string]} type - error/success/warning, defaults to error
     * @return {[void]} void
     */
    alertConfiguration.value.color = type;
    alertConfiguration.value.message = message;
    alertConfiguration.value.visible = true;
    alertConfiguration.value.internationalisedMessage =
        internationalisedMessage;
};

export const handleQueryParamsError = (
    queryObject: any,
    missingParam: string,
) => {
    /**
     * Logs missing query param error to Sentry and shows an alert to the user.
     * @param {[any]} queryObject
     * @param {[string]} missingParam
     * @returns {[void]}
     */
    configSentryError(
        "OneTimeLink",
        "handleOneTimeLinkLogic",
        `Missing required route/query parameter ${missingParam}. The provided link parameters are ${JSON.stringify(
            queryObject,
            null,
            2,
        )}`,
    );
    showAlert("alerts.unexpectedErrorWithRefreshAction", true);
};
